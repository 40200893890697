export default [
	{
		path: '',
		component: () => import('@/core/layouts/Panels/index.vue'),
		children: [
			{
				path: '',
				component: () => import('@/pages/Main/index.vue')
			},

			{
				path: '/panel/:group',
				component: () => import('@/pages/Panel/index.vue')
			},

			{
				path: '/publications/:parent',
				component: () => import('@/pages/Publications/index.vue')
			},
			{
				path: '/publications/:parent/:guid',
				component: () => import('@/pages/Publication/index.vue')
			},

			{
				path: '/tables',
				component: () => import('@/pages/Tables/index.vue'),
				meta: {
					title: 'Список таблиц',
					roles: ['admin']
				}
			},
			{
				path: '/tables/:table',
				component: () => import('@/pages/Table/index.vue')
			},

			{
				path: '/printbarcodes',
				component: () => import('@/pages/PrintBarcodes.vue'),
				meta: {
					title: 'Печать штрих-кодов'
				}
			},

			{
				path: '/patients',
				component: () => import('@/tables/patients/List/Index.vue'),
				meta: {
					roles: ['admin', 'patients']
				}
			},

			{
				path: '/inspection_pgi',
				component: () => import('@/pages/InspectionPgi/index.vue'),
				meta: {
					roles: ['admin', 'statistics', 'manager', 'dispatcher']
				}
			},

			{
				path: '/pgi',
				component: () => import('@/tables/pgi/List/Index.vue'),
				meta: {
					roles: ['admin', 'pgi']
				}
			},

			{
				path: '/rates',
				component: () => import('@/tables/rates/forms/List.vue'),
				meta: {
					roles: ['admin', 'economist']
				}
			},
			{
				path: '/paid_services',
				component: () => import('@/tables/paid_services/List/Index.vue'),
				meta: {
					roles: ['admin', 'economist', 'paid-services']
				}
			},

			{
				path: '/autopsy_research',
				component: () => import('@/tables/autopsy_research/List/Index.vue'),
				meta: {
					roles: ['admin', 'autopsy', 'auditor', 'checker']
				}
			},

			{
				path: '/death_certificate',
				component: () => import('@/tables/death_certificate/List/Index.vue'),
				meta: {
					roles: ['admin', 'autopsy', 'auditor']
				}
			},

			{
				path: '/reg_bodies',
				component: () => import('@/tables/reg_bodies/List/Index.vue'),
				meta: {
					roles: ['admin', 'autopsy', 'auditor', 'checker']
				}
			},
			{
				path: '/messages',
				component: () => import('@/pages/Messages/index.vue'),
				meta: {
					title: 'Сообщения'
				}
			}
		]
	},
	{
		path: '/profile',
		component: () => import('@/pages/Profile/index.vue'),
		meta: {
			title: 'Профиль',
			breadCrumb: [
				{
					route: '/profile',
					icon: 'icon icon-user',
					caption: 'Профиль'
				}
			]
		},
		children: [
			{
				path: '',
				component: () => import('@/pages/Profile/page.vue'),
				meta: {
					title: 'Основные данные',
					icon: 'icon icon-more-horizontal',
					breadCrumb: [
						{
							route: '/profile',
							icon: 'icon icon-user',
							caption: 'Профиль'
						},
						{
							icon: 'icon icon-book-open mr-2',
							caption: 'Основные данные'
						}
					]
				}
			},
			{
				path: 'page',
				component: () => import('@/pages/Profile/page.vue'),
				meta: {
					title: 'Основные данные',
					icon: 'icon icon-book-open mr-2',
					breadCrumb: [
						{
							route: '/profile',
							icon: 'icon icon-user',
							caption: 'Профиль'
						},
						{
							icon: 'icon icon-book-open mr-2',
							caption: 'Основные данные'
						}
					]
				}
			},
			{
				path: 'equipment',
				component: () => import('@/pages/Profile/equipment.vue'),
				meta: {
					title: 'Оборудование',
					icon: 'icon icon-printer',
					breadCrumb: [
						{
							route: '/profile',
							icon: 'icon icon-user',
							caption: 'Профиль'
						},
						{
							icon: 'icon icon-printer',
							caption: 'Оборудование'
						}
					]
				}
			}
		]
	},
	{
		path: '/user/:guid',
		component: () => import('@/pages/UserProfile/index.vue'),
		meta: {
			title: 'Профиль'
		},
		children: [
			{
				path: '',
				component: () => import('@/pages/UserProfile/AboutUser.vue'),
				meta: {
					title: 'О пользователе',
					icon: 'icon-user'
				}
			},
			{
				path: 'aboutuser',
				component: () => import('@/pages/UserProfile/AboutUser.vue'),
				meta: {
					title: 'О пользователе',
					icon: 'icon-user'
				}
			}
		]
	},
	{
		path: '/login',
		component: () => import('@/pages/auth/Login.vue'),
		meta: {
			layout: 'empty-layout'
		}
	},
	{
		path: '/404',
		component: () => import('@/pages/Page404.vue')
	},
	{
		path: '/:pathMatch(.*)',
		component: () => import('@/pages/Page404.vue')
	}
]
